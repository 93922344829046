.mute {
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 0;
  padding: 12px;
  height: auto !important; /* To override article hero direct selector */

  @media (--large) {
    padding: var(--spacing-sm);
  }

  & svg {
    --svg-color: #fff;
    width: 20px;
    height: 20px;

    @media (--large) {
      width: 24px;
      height: 24px;
    }
  }

  & button {
    background: transparent;
    appearance: none;
    color: #000;
    border: 0;
    cursor: pointer;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.1);

    @media (--large) {
      width: 39px;
      height: 39px;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.3);
    }
  }
}
