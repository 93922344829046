.hubspotForm {
  & ul {
    list-style: none;
    padding-inline: 0;
  }
  & fieldset {
    all: unset;
    display: block;
    max-width: 100% !important;
  }
  & input {
    width: 100% !important;
    border: 1px solid var(--color-divider);
    background: transparent;
    padding: var(--spacing-xs);
    &:focus {
      outline: 2px solid var(--color-divider);
    }
    &[type="submit"] {
      --button-padding-h: 24px;
      --button-padding-v: 12px;
      --button-br: 0;
      --button-gap: 8px;
      --svg-color: var(--color-white);

      font-weight: 300;
      line-height: 1;
      letter-spacing: 0.01;
      display: inline-block;
      min-width: 80px;
      transition: all ease 150ms;
      cursor: pointer;
      align-items: center;
      background: var(--color-dark);
      color: var(--color-white);
      padding: var(--button-padding-v) var(--button-padding-h);
      border-radius: var(--button-br);
      appearance: none;
      border: 0;
      justify-content: center;
      text-decoration: none;
      line-height: 20px;
      text-align: center;
      /* display: flex; need to figure out how to handle icons better */
      @extend %typo-subtitle2;

      @media (--small) {
        &:hover {
          background: var(--neutral-7);
          color: var(--color-white);
          --svg-color: var(--color-white);
        }
      }

      &.outlined:not(:hover) {
        background: transparent;
        border: 1px solid var(--color-primary);
        color: var(--color-primary);
        --svg-color: var(--color-primary);
      }

      &:disabled {
        cursor: default;
        opacity: 0.3;

        &:hover {
          /*color: var(--color-text-disabled);*/
        }
      }

      & svg {
        width: 14px;
        height: 14px;
      }

      & path {
        transition: all ease 150ms;
      }

      & span {
        display: flex;
        gap: var(--spacing-8);
        align-items: center;
      }
    }

    &[type="checkbox"] {
      vertical-align: middle;
      width: initial !important;
    }
  }
}
