.textHero {
  text-align: center;
  padding: var(--spacing-md);
  display: grid;
  place-content: center;
  gap: var(--spacing-md);
  aspect-ratio: 1 / 1;

  & .text {
    display: grid;
    gap: var(--spacing-sm);
    & .title {
      @extend %typo-headline2;
    }
  }
}

.hero {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);
  --aspect: var(--hero-aspect, 16 / 9);

  @media (--mobile-only) {
    max-width: 100%;
  }

  & .contentContainer {
    aspect-ratio: var(--aspect);
    position: relative;

    & img,
    & video {
      aspect-ratio: var(--aspect);
      object-fit: cover;
    }

    & .stickyContent {
      justify-content: flex-end;
    }

    & .content {
      position: absolute;
      inset: 0;
      padding: var(--spacing-xs);
      display: flex;
      flex-direction: column;
    }

    & .title {
      @extend %typo-headline2;
      color: var(--color-white);
      width: 100%;
      text-align: center;

      @media (--small) {
        text-align: left;
      }
      &:not(.sticky) {
        margin-top: auto;
      }

      @media (--small) {
        text-align: var(--hero-alignment, center);
      }

      &.sticky {
        position: sticky;
        bottom: var(--spacing-md);
      }
    }
  }

  & .description {
    @extend %typo-body2;
  }

  & .link {
    @extend %typo-body2;
    text-decoration: underline;
    text-underline-offset: 2px;
    transition: text-decoration-thickness 0.2s;
    color: inherit;
    @media (hover: hover) {
      &:hover {
        text-decoration-thickness: 1.5px;
      }
    }
  }
}
