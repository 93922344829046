.checkbox {
  display: flex;
  align-items: center;
  gap: var(--gap);
  cursor: pointer;
  position: relative;
  margin: var(--spacing-16) 0;
  @extend %typo-caption1;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  & a {
    color: var(--color-primary);
    text-decoration: underline;
    font-weight: 600;
  }

  &:has(input:disabled) {
    cursor: default;
  }
}

.footerCheckboxLabel {
  display: flex;
  align-items: center;
  gap: var(--gap);
  cursor: pointer;
  position: relative;
  margin: var(--spacing-16) 0 var(--spacing-lg) 0;
  @extend %typo-caption1;

  @media (--small) {
    margin: var(--spacing-16) 0 0 0;
  }

  &:has(input:disabled) {
    cursor: not-allowed;
  }

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 1px;
    width: 1px;

    &:focus-visible + span {
      outline: 1px solid;
    }

    &:disabled + .footerFauxInput {
      outline: 1px solid var(----color-divider-inactive);
    }
  }

  & a {
    color: var(--color-primary);
    text-decoration: underline;

    &:focus-visible {
      outline: 1px solid var(--color-primary);
    }
  }
}

.footerFauxInput {
  box-sizing: border-box;
  position: relative;
  width: var(--spacing-24);
  height: var(--spacing-24);
  border: 1px solid var(--color-primary);
  flex-shrink: 0;
  margin-right: var(--spacing-12);

  &:hover {
    outline: 1px solid;
  }

  &.checked {
    &::after {
      content: "";
      display: block;
      background: url("/images/ui-checked-mark.svg") no-repeat;
      background-size: var(--spacing-20);
      height: 22px;
      width: auto;
    }
  }
}

.error {
  font-size: var(--font-size-12);
  margin-top: var(--spacing-4);
  position: absolute;
  bottom: -30px;
  color: var(--color-base);
  font-weight: 600;
}

.fauxInput {
  box-sizing: border-box;
  position: relative;
  width: var(--spacing-24);
  height: var(--spacing-24);
  border: 1px solid var(--neutral-5);
  flex-shrink: 0;
  margin-right: var(--spacing-12);

  &:hover {
    border-color: var(--color-primary);
  }

  &.checked {
    border-color: var(--color-primary);
    &::after {
      content: "";
      display: block;
      background: url("/images/ui-checked-mark.svg") no-repeat;
      background-size: var(--spacing-20);
      width: var(--icon-md);
      height: var(--icon-md);
    }

    &.disabled {
      border-color: var(--color-divider-inactive);
      &:hover {
        cursor: default;
      }
    }
  }
}
