.wrapper {
  &[data-only-desktop="true"] .desktop {
    display: block !important;
  }

  & video {
    object-fit: cover;
    display: block;
  }
}

.desktop {
  display: none;
  overflow: hidden;
  position: relative;
  @media (--large) {
    display: block;
  }
}

.mobile {
  display: block;
  overflow: hidden;
  position: relative;
  @media (--large) {
    display: none;
  }
}

/* comment */
