.articleGrid {
  --row-gap: var(--spacing-xl);
  --column-gap: 16px;
  margin-block: var(--spacing-md);
  gap: var(--row-gap) var(--column-gap);
  display: grid;
  margin-inline: var(--smart-page-inset);
  grid-template-columns: 1fr;

  @media (--small) {
    grid-template-columns: repeat(12, 1fr);
  }
}

.articleHero {
  --row-gap: var(--spacing-16);
}

.filters {
  padding-top: var(--spacing-32);
}

.heroLeft {
  grid-column: 1 / 1;
  grid-row: auto;
  position: relative;
  margin-bottom: 84px;
  padding-bottom: 40px;
  @media (--small) {
    padding-bottom: 0;
    grid-column: 1/5;
    grid-row: 1/3;
    margin-bottom: 0;
  }

  &__inner {
    @media (--small) {
      position: sticky;
      top: calc(var(--header-height) + var(--spacing-16));
    }
  }
}

.title {
  @extend %typo-headline1;
  font-size: var(--font-size-2xl);
  font-weight: 400;
  text-transform: uppercase;
  grid-column: 1/1;

  @media (--small) {
    grid-column: 1 / span 2;
    font-size: var(--font-size-4xl);
  }
}

.tabs {
  @extend %typo-button;
  font-size: var(--font-size-s);
  margin-top: var(--spacing-sm);
  margin-bottom: 22px;
  grid-column: 1 / 1;
  display: flex;
  flex-wrap: wrap;
  row-gap: var(--spacing-8);

  @media (--small) {
    font-size: var(--font-size-s);
    grid-column: 1 / span 3;
    grid-row: 2;
    margin-top: var(--spacing-xs);
    margin-bottom: var(--spacing-64);
  }

  & .activeTab {
    text-decoration: underline;
    text-underline-offset: var(--spacing-8);
  }

  & a {
    height: calc(
      var(--plp-button-height)
    ); /* Minus tabs row-gap. To ensure buttons have some space between them */
    display: flex;
    align-items: center;
  }

  @extend %gap-scaling;
}

.mobileImage {
  display: block;
  position: absolute;
  z-index: var(--z-behind);
  width: 100%;
  height: 100%;
  &.heroImage {
    & img {
      aspect-ratio: 1 / 1;
      object-fit: cover;
    }
  }

  @media (--small) {
    display: none;
  }
}

.desktopImage {
  display: none;

  @media (--small) {
    display: block;
    margin-bottom: var(--row-gap);
    grid-column: 6 / span 7;
    grid-row: 1;
  }
}
