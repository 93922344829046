.menu {
  display: flex;
  flex-direction: column;
  gap: var(--margin-spacing);
  justify-content: space-between;
  height: 100%;
  padding: 0 var(--page-inset);
}

.mobileModal {
  display: block;

  @media (--large) {
    display: none;
  }
}

.desktopModal {
  display: none;

  @media (--large) {
    display: block;
  }

  &.open a {
    text-decoration: underline;
    text-underline-offset: 9px;
  }
}

.current {
  text-decoration: underline;
  text-underline-offset: 9px;
}

.menuSectionLink {
  & .menuLink {
    color: var(--menu-link-color, inherit);
  }
}

.menu__list {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;

  list-style: none;
  padding: 0;
  margin: 0;
  gap: var(--margin-spacing);

  & a {
    @extend %typo-headline2;
    color: var(--color-white);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &.secondary {
    flex: 0;

    & a {
      @extend %typo-body2;
    }
  }
}

.menuWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 300ms;
  --hero-aspect: 1;

  @media (--mobile-only-lg) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.hasActive {
  transform: translateX(-100%);

  @media (--small) {
    transform: none;
  }
}

.outerMenu {
  @media (--large) {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: var(--spacing-16);

    & a,
    button {
      color: var(--color-primary);
      text-decoration: none;
      padding-bottom: var(--header-extra-click-area);

      &:hover,
      &:active,
      &:focus-visible {
        text-underline-offset: 9px;
        text-decoration: underline;
      }
    }
  }

  @media (--mobile-only-lg) {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    gap: var(--spacing-24);
    --svg-color: var(--color-white);
    @extend %typo-headline2;
  }
}

.menuModalWrapper {
  position: absolute;
  aspect-ratio: 33 / 6;
  top: calc(100% - 2px);
  left: 0;
  width: 100%;
  transition: all 300ms 0ms linear;
  background: var(--neutral-2);

  @media (--small) {
    transform: none;
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }

  &.open {
    @media (--small) {
      transform: none;
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
      transition: all 300ms 100ms;
    }
  }
}

.desktopMenu {
  &__section {
    width: 100%;
    padding-bottom: var(--spacing-24);
    position: absolute;
    top: 0;
    left: 0;
    z-index: var(--z-behind);
    color: var(--color-primary-contrast);
    transform: translateX(200%);
    transition: all 300ms 0ms;
    height: auto;

    @media (--small) {
      transform: none;
      opacity: 0;
    }

    &.active {
      transform: translateX(100%);
      z-index: calc(var(--z-behind) + 1);
      @media (--small) {
        transform: none;
        opacity: 1;
        transition-delay: 100ms;
      }
    }

    @media (--small) {
      bottom: unset;
    }

    & a {
      color: var(--color-primary-contrast);
      text-decoration: none;
      padding: var(--spacing-12) 0;
      display: inline-block;

      @extend %typo-headline2;

      @media (--small) {
        @extend %typo-body2;
        padding: var(--spacing-4) 0;
      }

      &:hover {
        text-decoration: underline;
        text-underline-offset: 9px;
      }
    }

    & ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
    }

    & .content__card {
      width: 100%;
      flex-shrink: 0;
      padding: 0;

      & a {
        width: 100%;
      }

      & img {
        aspect-ratio: 1 / 1;
        object-fit: cover;
        border-radius: 0px;
        overflow: hidden;
        width: 100%;
      }

      & span {
        margin-top: var(--spacing-8);

        @extend %typo-body2;

        @media (--small) {
          @extend %typo-h3;
        }
      }

      &:first-child {
        margin-left: var(--page-inset);

        @media (--small) {
          margin: 0;
        }
      }

      &:last-child {
        margin-right: var(--page-inset);

        @media (--small) {
          margin: 0;
        }
      }
    }
  }

  &__wrap {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    min-height: 100%;
    column-gap: var(--column-gap);
    margin-bottom: 0;

    @media (--small) {
      flex-direction: row;
      justify-content: initial;
      height: initial;
      max-width: var(--page-inset-width) !important;
    }
  }

  &__column {
    grid-column: 3 / span 8;
    display: flex;
    width: 100%;
    align-items: flex-end;
  }

  &__sectionLinks {
    & li {
      @extend %chip-elements;
    }
  }

  &__columnTitle {
    opacity: 0.5;
    margin-bottom: var(--spacing-8);
    display: block;
  }

  &__bloks {
    grid-column: 11 / span 2;
    display: flex;
    overflow-x: scroll;

    @media (--small) {
      width: 100%;
      border: none;
      overflow-x: initial;
      padding-top: var(--spacing-12);

      --hero-alignment: left;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding-top: var(--spacing-16);

    --svg-color: var(--color-white);

    & svg {
      position: absolute;
      left: var(--page-inset);
    }

    & span {
      flex: 1;
      width: 100%;
      text-align: center;
    }
  }
}

.outerMenu {
  @media (--mobile-only-lg) {
    & .accordionItem {
      border-bottom: 0;
    }

    & .accordionTrigger {
      @extend %typo-headline2;
      padding: 0 var(--page-inset);
      justify-content: space-between;
      align-items: center;
    }

    & li {
      padding-block: var(--spacing-xs);
      border-bottom: 1px solid var(--color-primary);
    }

    & a {
      padding-inline: var(--page-inset);
      color: var(--color-primary);
      text-decoration: none;
      display: block;
    }
  }

  & .accordionContent {
    @media (--mobile-only-lg) {
      @extend %typo-body2;
      padding-top: var(--spacing-2xs);
      padding-bottom: 0;

      & div {
        padding-block: var(--spacing-3xs);
      }
    }
  }
}

.secondaryLinks {
  width: 100%;
  gap: var(--spacing-8);
  padding-top: var(--spacing-80);
  padding-inline: var(--page-inset);
  display: flex;
  flex-direction: column;
  @extend %typo-body2;

  & a {
    color: var(--color-primary);
    padding: var(--spacing-8) 0;
  }

  @media (--large) {
    display: none;
  }
}

.divider {
  height: var(--spacing-16);
}
