.root {
  contain: paint;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: var(--column-gap);
  row-gap: 8px;
  grid-auto-rows: min-content;

  @media (--small) {
    row-gap: var(--row-gap);
  }

  & > * {
    width: 100%;
    --hero-max-height: 100%;
  }

  & .side {
    grid-column-start: var(--start);
    grid-column-end: var(--end);

    --span: span 12;
    --hero-aspect: 5 / 6;

    @media (--small) {
      --span: span 5;
    }

    &.alignSelfCenter {
      display: flex;
      align-items: center;
    }

    &:first-child {
      --start: 1;
      --end: var(--span);

      @media (--small) {
        &.alignRight {
          --start: var(--span);
          --end: 8;
        }
        &.alignCenter {
          --start: 2;
        }

        &.wide {
          --start: 1;
          --end: var(--span);
        }
      }
    }

    &:last-child {
      --start: var(--span);
      --end: -1;

      @media (--small) {
        &.alignLeft {
          --end: -3;
        }
        &.alignCenter {
          --end: -2;
        }
        &.wide {
          --end: -1;
        }
      }
    }

    &.normal {
      --hero-aspect: 5 / 6;
      --span: span 12;

      @media (--small) {
        --span: span 5;
      }
    }
    &.wide {
      @media (--small) {
        --hero-aspect: 1207 / 1029;
        --span: span 7;
        height: 100%;
        max-height: 100%;

        /* Hero specific overrides */
        & :global(.hero) {
          height: 100%;
          & div,
          img {
            height: 100%;
          }
        }
      }
    }
  }
}
