.base {
  &.full {
    & img {
      width: 100%;
      @media (--mobile-only) {
        object-fit: cover;
        aspect-ratio: 3 / 4;
      }
    }
  }

  &:not(.full) * {
    border-radius: 4px;
  }
}

@media (--large) {
  .left *,
  .right * {
    display: block;
    width: 50%;
  }

  .right * {
    margin-left: auto;
  }
}

.video {
  position: relative;
  aspect-ratio: 16 / 9;

  iframe {
    width: 100%;
  }
}

.desktop {
  display: none;

  @media (--small) {
    display: block;
  }
}

.mobile {
  display: block;

  @media (--small) {
    display: none;
  }
}
